import React from "react";
import { v4 as uuid } from "uuid";
const Certifications = ({ data }) => {
  return (
    <section
      id="certifications"
      className="section certifications"
      // style={{
      //   backgroundImage: `radial-gradient(circle closest-corner at 0%, rgba(0, 0, 0, 0) 100%, #f0f0f0), url(${data.background?.data?.attributes?.url})`,
      //   backgroundPosition: "0 0, 0 0",
      //   backgroundSize: "auto, auto",
      //   backgroundAttachment: "scroll, fixed",
      // }}
    >
      <div className="w-layout-blockcontainer container certifications w-container">
        <div className="right-div">
          <h2 className="right">{data.heading}</h2>
          <div className="sub-heading right">{data.subheading}</div>
        </div>
        <div className="w-dyn-list">
          <div role="list" className="certification-list w-dyn-items">
            {data.certifications?.map((el) => {
              let item = el?.certification?.data?.attributes;
              return (
                <div key={uuid()} role="listitem" className="certification w-dyn-item" style={{ minWidth: "200px" }}>
                  <img src={item?.image?.data?.[0]?.attributes?.url} loading="lazy" alt="" className="image-5" />
                  <div className="div-block-2">
                    <div className="cert-name">{item.name}</div>
                    <div className="cert-authority">{item.authority}</div>
                    <div className="cert-date"> {item.date}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certifications;
