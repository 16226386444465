import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import RichText from "../components/RichText";
import "../css/Portfolio.css";

const Projects2 = ({ data: rawData }) => {
  let data = { ...rawData };
  data.heading = "Let's have a look at my";
  data.subheading = "Portfolio";

  const [index, setIndex] = useState(0);
  const active = { ...data.projects[index].project.data.attributes, id: data.projects[index].project.data.id };

  // console.log(active);
  return (
    <section id="projects" class="section">
      <div class="w-layout-blockcontainer container w-container">
        <h2 className="portfolioHeading">{data.heading}</h2>
        <br />
        <h2 className="portfolioHeading gold">{data.subheading}</h2>
        <div class="project-cards">
          {data.projects.map((el, i) => {
            return <Card data={el} key={i} index={index} setIndex={setIndex} i={i} />;
          })}
        </div>
        <div class="dots">
          {data.projects.map((el, i) => {
            return <div class={`dot ${index === i && "active"}`} onClick={() => setIndex(i)} key={uuid()}></div>;
          })}
        </div>

        <div class="tags" style={{ display: "flex", flexWrap: "wrap" }}>
          {active?.tags?.map((el, i) => {
            return (
              <div class="tag" key={i}>
                {el.value}
              </div>
            );
          })}
        </div>
        <div>
          <div class="div-block-13">
            <h3 class="heading">{active.name}</h3>
            <a href={`/projects/${active.id}`}>
              <img src="images/arrow45.png" loading="lazy" alt="" class="new-arrow" />
            </a>
          </div>
          <p class="paragraph-2">{active.description}</p>
        </div>
      </div>
    </section>
  );
};

export default Projects2;

const Card = ({ data, index, setIndex, i }) => {
  const item = data?.project?.data?.attributes;
  //   console.log(item);
  if (!item) return null;
  return (
    <div
      class={`project-card ${index === i && "active"} ${index > i && "hideCard"}`}
      onClick={() => setIndex(i)}
      style={{
        backgroundImage: `linear-gradient(0deg, #000, #0000 28%), url(${item.coverPhoto?.data?.attributes?.url || "../images/microsoft-365-TLiWhlDEJwA-unsplash-min.jpg"})`,
      }}
    >
      <div class="text-block-6">{item.name}</div>
    </div>
  );
};
